import React from 'react';
import Header from '../../components/Header';
import '../../styles/brochure.css';
import ModalCard from './ModalCard';
import data from "../../data.js";
import tower from "../../assets/images/brochure/sanfrancisco/tower.png";
import cloud1 from "../../assets/images/brochure/sanfrancisco/cloud1.png";
import cloud2 from "../../assets/images/brochure/sanfrancisco/cloud2.png";
import cloud3 from "../../assets/images/brochure/sanfrancisco/cloud3.png";
import cloud4 from "../../assets/images/brochure/sanfrancisco/cloud4.png";
import cloud5 from "../../assets/images/brochure/sanfrancisco/cloud5.png";
import cloud6 from "../../assets/images/brochure/sanfrancisco/cloud6.png";
import cloud7 from "../../assets/images/brochure/sanfrancisco/cloud7.png";
import cloud8 from "../../assets/images/brochure/sanfrancisco/cloud8.png";
import cloud9 from "../../assets/images/brochure/sanfrancisco/cloud9.png";
import cloud10 from "../../assets/images/brochure/sanfrancisco/cloud10.png";
import cloud11 from "../../assets/images/brochure/sanfrancisco/cloud11.png";
import birdGif from "../../assets/images/brochure/sanfrancisco/bird-gif.gif";
import cloudyGif from "../../assets/images/brochure/sanfrancisco/cloudy-gif.gif";

const Brochure = (props) => {
  const cardDataDefault = {
    slug: "",
    title: "",
    color: "",
    backgroundImage: "",
    backInfo: [],
  }
  const [ curentCard, setCurrentCard] = React.useState(cardDataDefault);
  const towerRef =  React.useRef(null);
  const cloud1Ref =  React.useRef(null);
  const cloud2Ref =  React.useRef(null);
  const cloud3Ref =  React.useRef(null);
  const cloud4Ref =  React.useRef(null);
  const cloud5Ref =  React.useRef(null);
  const cloud6Ref =  React.useRef(null);
  const cloud7Ref =  React.useRef(null);
  const cloud8Ref =  React.useRef(null);
  const cloud9Ref =  React.useRef(null);
  const cloud10Ref =  React.useRef(null);
  const cloud11Ref =  React.useRef(null);
  const [ isVisible1, setIsVisible1] = React.useState(false);
  const [ isVisible2, setIsVisible2] = React.useState(false);
  const [ isVisible3, setIsVisible3] = React.useState(false);
  const [ isVisible4, setIsVisible4] = React.useState(false);
  const [ isVisible5, setIsVisible5] = React.useState(false);
  const [ isVisible6, setIsVisible6] = React.useState(false);
  const [ isVisible7, setIsVisible7] = React.useState(false);
  const [ isVisible8, setIsVisible8] = React.useState(false);
  const [ isVisible9, setIsVisible9] = React.useState(false);
  const [ isVisible10, setIsVisible10] = React.useState(false);
  const [ isVisible11, setIsVisible11] = React.useState(false);

  React.useEffect( () => {
    window.addEventListener("scroll", () => {
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.12) + cloud1Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible1(true);
      }
      
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.15) + cloud2Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible2(true);
      }

      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.17) + cloud3Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible3(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.32) + cloud4Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible4(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.36) + cloud5Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible5(true);
      }

      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.43) + cloud6Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible6(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.435) + cloud7Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible7(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.51) + cloud8Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible8(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.56) + cloud9Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible9(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.575) + cloud10Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible10(true);
      }
      if(window.innerHeight + window.pageYOffset > (towerRef.current.offsetHeight * 0.615) + cloud11Ref.current.clientHeight + (window.innerHeight / 2)){
        setIsVisible11(true);
      }
      // if(window.height + )
      // console.log("as",window.innerHeight, window.pageYOffset )
      // console.log("ref1",ref1.current.offsetHeight)
      // console.log("ref2",ref2.current.offsetHeight)
    });
  },[])

  const cleanCardData = () => {
    setCurrentCard(cardDataDefault);
  }
  return (
    <div>
      <Header building={props.building}/>
      <div className="brochure">
        <div className="introduction" ref={towerRef}>
          <div className="container">
            <h4 className="title">Welcome to<br/>Salesforce Tower<br/>San Francisco</h4>
            <img
              alt=""
              src={cloud1}
              ref={cloud1Ref}
              className={`floating cloud-1${isVisible1 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud2}
              ref={cloud2Ref}
              className={`floating cloud-2${isVisible2 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud3}
              ref={cloud3Ref}
              className={`floating cloud-3${isVisible3 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud4}
              ref={cloud4Ref}
              className={`floating cloud-4${isVisible4 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud5}
              ref={cloud5Ref}
              className={`floating cloud-5${isVisible5 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud6}
              ref={cloud6Ref}
              className={`floating cloud-6${isVisible6 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud7}
              ref={cloud7Ref}
              className={`floating cloud-7${isVisible7 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud8}
              ref={cloud8Ref}
              className={`floating cloud-8${isVisible8 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud9}
              ref={cloud9Ref}
              className={`floating cloud-9${isVisible9 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud10}
              ref={cloud10Ref}
              className={`floating cloud-10${isVisible10 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloud11}
              ref={cloud11Ref}
              className={`floating cloud-11${isVisible11 ? ' active' : ''}`}
            />
            <img
              alt=""
              src={cloudyGif}
              className={`floating cloudy-3 active`}
            />
            <img
              alt=""
              src={birdGif}
              className={`floating bird active`}
            />
          </div>
          <img src={tower} className="brochure-tower" alt=""/> 
        </div>
        <div className="cards">
          <h4 className="title">Get to know us better</h4>
          <div className="cards__container">
            {data.cards.map( item => (
              <div className="card" key={item.slug}>
                <div className={`box ${item.slug} ${curentCard.title ? "inactive" : ""}`} onClick={ () => setCurrentCard({...item})}>
                  <img alt="" src={require(`./../../assets/images/brochure/sanfrancisco/${item.image}`)}></img>
                  <h4>{item.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalCard card={curentCard} cleanCardData={cleanCardData}/>
    </div>
  );
}

export default Brochure;
