import React from 'react';
import { PERMISSION_STATUS } from '../../util/constants';

const Permissions = (props) => {
  const { arePermissionsAllowed, startGeolocation, startOrientation, startCamera,
    statusCamera, statusGeolocation, statusOrientation, data } = props;
    const hasAnyError = statusCamera.status === PERMISSION_STATUS.ERROR || statusGeolocation.status === PERMISSION_STATUS.ERROR || statusOrientation.status === PERMISSION_STATUS.ERROR;

  const permissions = [
    {
      slug: "orientation",
      name: "Orientation",
      status: statusOrientation.status,
      message: statusOrientation.message,
      startFunction: startOrientation
    },
    {
      slug: "geolocation",
      name: "Geolocation",
      status: statusGeolocation.status,
      message: statusGeolocation.message,
      startFunction: startGeolocation
    },
    {
      slug: "camera",
      name: "Camera",
      status: statusCamera.status,
      message: statusCamera.message,
      startFunction: startCamera
    },

  ]
  return (
    !arePermissionsAllowed ?
    <div className={`permissions ${hasAnyError ? "error" : ""}`}>
      <div className="content">
        <h4 className="font-app-title">Enable Permissions</h4>
          {permissions.map( item => (
            <div key={`permission-${item.name}`}>
              <div className="row">
                <div>
                  <h3 className="font-app-subtitle">{item.name}</h3>
                  <p className="font-app-body">
                    {data[item.slug].description}
                  </p>
                </div>
                <button
                  className={`${item.status === PERMISSION_STATUS.ERROR ?  "error" : ""}`}
                  disabled={item.status === PERMISSION_STATUS.INITIAL ? false : true}
                  onClick={item.startFunction}
                >
                  {item.status === PERMISSION_STATUS.LOADING ?
                    <div className="loader-spin"/>
                  : item.status === PERMISSION_STATUS.CORRECT ?
                    <span className="checkmark">
                      <div className="checkmark_stem"></div>
                      <div className="checkmark_kick"></div>
                    </span>
                  : item.status === PERMISSION_STATUS.ERROR ? 
                    <span className="cross">X</span>
                  : "Enable"
                  }
                </button>
              </div>
              {item.message.generic && 
                item.message.generic.map( (item,index) => (
                  <p key={`generic-${index}`} className="font-app-body-error error-message">
                    {item}
                  </p>
                ))
              }
              {item.message.troubleshooting ? (
                <p className='font-app-body error-title'>Troubleshooting</p>
              )
                : null
              }
              {item.message.troubleshooting && 
                <ol>
                  {item.message.troubleshooting.map( (item, index) => (
                  <li key={`troubleshooting-${index}`} className="font-app-body-error error-message">
                    {item}
                  </li>
                  ))}
                </ol>
              }
            </div>
          ))}
      </div>
    </div>
    : null
  );
}

export default Permissions;
