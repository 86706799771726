import React from 'react';

const Monitoring = (props) => {
  const { orientation, startGeolocation, geolocation } = props;

  const toggleCheckbox = (e) => {
    startGeolocation(e.target.checked);
  }

  return (
    <>
      <span className='orientation-test'>{Math.round(orientation)}</span>
      {/* <div className='toggle'>
        <p>Geolocation Accuracy</p>
        <label className="switch">
          <input type="checkbox" onChange={toggleCheckbox}/>
          <span className="slider round"></span>
        </label>
        <p>Latitude {geolocation.latitude}</p>
        <p>Longitude {geolocation.longitude}</p>
      </div> */}
    </>
  )
}

export default Monitoring;
