import React from 'react';
const PageNotFound = () => {

  return (
    <div className='not-found'>
      <h4>Looks like you've lost your way!</h4>
    </div>
  );
}

export default PageNotFound;
