import { useCallback, useEffect, useRef, useState } from 'react';
import { getMobileOperatingSystem, getMobileBrowser } from './functions.js';
import { PERMISSION_INITIAL_STATE, PERMISSION_STATUS } from "./constants.js";
import data from "../data";

export const useDeviceOrientation = () => {
  const [status, setStatus] = useState(PERMISSION_INITIAL_STATE);
  const [orientation, setOrientation] = useState(0);
  const [type, setType] = useState("absolute");
  const mobileOS = getMobileOperatingSystem();
  const browser = getMobileBrowser();
  const absoluteSensor = useRef(null);
  const orientationMessages = data.artrailmap.permissions.orientation.errorMessages;
  const CALIBRATION_DEGREES = 250;
  const INTENDED_SENSOR_RESETTING_TIME_MS = 500;

  const startOrientationCallback = () => {
    chooseSupportedOrientationAPIByOS();
  };

  const chooseSupportedOrientationAPIByOS = async () => {
    try{
      setStatus({status: PERMISSION_STATUS.LOADING, message: ""});
      if (mobileOS === "iOS") {
        if(await checkDeviceOrientationEvent()){
          initDeviceOrientationListener(); 
        }
      }else if(mobileOS === "Android"){
        if(await checkAbsoluteOrientationPermissions()){
          initAbsoluteOrientationSensor();
        };
      }
      else{
        initDeviceOrientationListener();
        // throw new Error('Not iOS or Android, AR experience not supported');
      }
    }catch(e) {
      // let message = orientationMessages.generic.join(" ");
      setStatus({
        status: PERMISSION_STATUS.ERROR, 
        message: {
          generic: orientationMessages.generic,
          troubleshooting: orientationMessages.troubleshooting[mobileOS] && orientationMessages.troubleshooting[mobileOS][browser],
        }
      });
    }
  }

  const checkDeviceOrientationEvent = async () => {
    if (!DeviceOrientationEvent) {
      throw new Error('Device orientation event is not supported by your browser');
    }

    if (
      DeviceOrientationEvent.requestPermission
      && typeof DeviceMotionEvent.requestPermission === 'function'
    ) {
      let permission = await DeviceOrientationEvent.requestPermission();
      if (permission !== 'granted') {
        throw new Error('Request to access the device orientation was rejected');
      }
    }
    return true;
  }

  const getWebkitCompassHeading = useCallback( (e) => {
    console.log("orinetation wekit",e.webkitCompassHeading);
    setOrientation(e.webkitCompassHeading);
  }, []);
  
  const calculateRelativeCompassHeading = useCallback( (e) => {
    const { alpha, beta, gamma} = e;

    // Convertors
    const degToRad = Math.PI / 180;
    const radToDeg = 180 / Math.PI;

    const _x = beta  ? beta  * degToRad : 0; // beta value
    const _y = gamma ? gamma * degToRad : 0; // gamma value
    const _z = alpha ? alpha * degToRad : 0; // alpha value

    const cX = Math.cos( _x );
    const cY = Math.cos( _y );
    const cZ = Math.cos( _z );
    const sX = Math.sin( _x );
    const sY = Math.sin( _y );
    const sZ = Math.sin( _z );

    // Calculate Vx and Vy components
    const Vx = - cZ * sY - sZ * sX * cY;
    const Vy = - sZ * sY + cZ * sX * cY;

    // Calculate compass heading
    let compassHeading = Math.atan( Vx / Vy );

    // Convert compass heading to use whole unit circle
    if( Vy < 0 ) {
      compassHeading += Math.PI;
    } else if( Vx < 0 ) {
      compassHeading += 2 * Math.PI;
    }

    compassHeading = compassHeading * radToDeg;
    compassHeading = compassHeading + CALIBRATION_DEGREES;
    if(compassHeading > 360){
      compassHeading = compassHeading - 360
    }
    setOrientation(compassHeading);
  }, [])

  const initDeviceOrientationListener = () => {
    console.log("starting ios absolute sensor");
    window.addEventListener("deviceorientation", getWebkitCompassHeading);
    setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
    setType("absolute");
  }

  const initDeviceOrientationRelative = () => {
    console.log("starting relative CALIBRATING")
    window.addEventListener("deviceorientation", calculateRelativeCompassHeading);
    setType("relative");
    // setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
  }

  const checkAbsoluteOrientationPermissions = async () => {
    if (navigator.permissions) {
      try{
        const response = await Promise.all([
          navigator.permissions.query({ name: "accelerometer" }),
          navigator.permissions.query({ name: "magnetometer" }),
          navigator.permissions.query({ name: "gyroscope" })
        ])
        if (response.every(result => result.state === "granted")) {
          return true;
        } else {
          console.log("Permission to use sensor was denied.");
          return false;
        }
      }catch(err){
        console.log("Integration with Permissions API is not enabled, still try to start app.");
        return true;
      }
    } else {
      console.log("No Permissions API, still try to start app.");
      return true;
    }
  }
  const initAbsoluteOrientationSensor = () => {
    
    const options = { frequency: 6, referenceFrame: 'device' };
    absoluteSensor.current = new window.AbsoluteOrientationSensor(options);

    console.log("starting absolute android sensor");
    absoluteSensor.current.addEventListener('reading', () => {
      // model is a Three.js object instantiated elsewhere.
      let q = absoluteSensor.current.quaternion;
      let heading = Math.atan2(2*q[0]*q[1] + 2*q[2]*q[3], 1 - 2*q[1]*q[1] - 2*q[2]*q[2])*(180/Math.PI);
      heading = Math.abs( heading - 360);
      if( heading > 360){
        heading = heading - 360
      }
      setOrientation(heading);
    });

    absoluteSensor.current.addEventListener('error', error => {
      if (error.name === 'NotReadableError') {
        console.log("Sensor is not available.");
      }
      throw new Error(error.name);
    });

    absoluteSensor.current.start();
    setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
    setType("absolute");
  }

  const stopIOSAbsoluteSensor = () => {
    console.log("Remove ios absolute sensor");
    window.removeEventListener('deviceorientation', getWebkitCompassHeading);
  };

  const stopRelativeSensor = () => {
    console.log("Remove relative sensor");
    window.removeEventListener('deviceorientation', calculateRelativeCompassHeading);
  };

  const stopAbsoluteOrientationSensor = () => {
    console.log("Remove android absolute sensor");
    if(absoluteSensor.current) absoluteSensor.current.stop();
  }

  const startOrientation = useCallback(startOrientationCallback, []);
  // const revokeAccess = useCallback(revokeAccessAsync, []);
  const startCalibration = () => {
    console.log("button calibrate")
    // Revoke all other orientation methods
    // invoke relative method
    stopAllSensors();
    setTimeout( () => initDeviceOrientationRelative(), 500);
  }

  const stopAllSensors = () => {
    stopIOSAbsoluteSensor();
    stopAbsoluteOrientationSensor();
    stopRelativeSensor();
  }
  const xd = useCallback(stopAllSensors, []);

  useEffect(() => {
    return () => {
      // console.log("demounting");
      xd();
    };
  }, [xd]);

  return {
    orientation,
    status,
    startOrientation,
    stopAllSensors,
    startCalibration,
    type,
    INTENDED_SENSOR_RESETTING_TIME_MS,
  };
};