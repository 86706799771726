import { useState } from "react";
const ButtonLoader = ({onClick, text, animationTimeMs}) => {
  const [isLoading, setLoading] = useState(false);
  const  addFixedLoadingAnimation = () => {
    setLoading(true);
    setTimeout( () => {
      onClick();
    //   setLoading(false);
    }, animationTimeMs);
  };
  return(
    <button className="calibration-button" onClick={addFixedLoadingAnimation}>
        {isLoading ? 
          <div className='loader-spin'/> 
        : text}
    </button>
  )
}

export default ButtonLoader;