import { useEffect } from "react"
export const usePageVisibility = (onPageHide, onPageResume) => {
  useEffect( () => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        onPageHide();
      } else {
        onPageResume();
      }
    }
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => document.removeEventListener("visibilitychange", onVisibilityChange);
  },[onPageResume])
}