import React from 'react';
import Landmark from "./Landmark";
import '../../styles/panoramicView.css';
import { copyArrayObjectsNTimes  } from '../../util/functions.js';
import { usePrevious  } from '../../util/hooks.js';

const PanoramicView = (props) => {
  const TOTAL_REPEATED_360_VIEW = 5;
  const PANORAMIC_DEGREES_ON_SCREEN = 30;
  const {
    orientation, 
    panoramicWidth, 
    geolocation,
    data,
    calcOrientationDegreesFromCurrentLocationToLandmark,
    arePermissionsAllowed,
    showCalibrationPage
  } = props;

  const _360ViewRef = React.useRef(null);
  const prevCompass = usePrevious(orientation);

  const MIDDLE_360_VIEW = Math.ceil(TOTAL_REPEATED_360_VIEW / 2);
  const TOTAL_SCREENS_TO_COMPLETE_360_VIEW = 360 / PANORAMIC_DEGREES_ON_SCREEN;
  const _360_VIEW_WIDTH_PX = panoramicWidth * TOTAL_SCREENS_TO_COMPLETE_360_VIEW;
  const _360_VIEW_WITH_DUPLICATES_WIDTH_PX = _360_VIEW_WIDTH_PX * TOTAL_REPEATED_360_VIEW;

  const [ landmarkActiveIndex, setLandmarkActiveIndex] = React.useState(null);
  const [ landmarks, setLandmarks ] = React.useState(
    copyArrayObjectsNTimes(data.landmarks,MIDDLE_360_VIEW)
  );
  const [ currentFrame, setCurrentFrame]  = React.useState(MIDDLE_360_VIEW);

  React.useEffect( () => {
    updateLandmarksPositionAround360View();
  }, [geolocation]);

  const updateLandmarksPositionAround360View = () => {
    const updatedLandmarks = landmarks.map( (landmark,index) => {
      if(!process.env.REACT_APP_MOCK_LANDMARKS_LOCATION){
        landmark.degrees = calcOrientationDegreesFromCurrentLocationToLandmark(geolocation, landmark.coordinates);
      }
      return landmark;
    });
    setLandmarks(updatedLandmarks);
  }

  const setOrientationDegreesEquidistantly = (index) => {
    return 360 / data.landmarks.length * (index % data.landmarks.length);
  }

  React.useEffect( () => {
    _360ViewRef.current.style.width = `${_360_VIEW_WITH_DUPLICATES_WIDTH_PX}px`;
  }, [panoramicWidth]);

  React.useEffect( () => {
    checkIfTranslateOneSpin(orientation);
    move360View(orientation);
  }, [orientation]);

  const checkIfTranslateOneSpin = (compass) => {
    if(prevCompass - compass > 180){
      setCurrentFrame(currentFrame + 1);
    }else if(prevCompass - compass < -180){
      setCurrentFrame(currentFrame - 1);
    }
  }
  
  const move360View = (compass) => {
    const positionView = _360_VIEW_WIDTH_PX * ((currentFrame - 1) + (compass / 360));
    const centerViewOnScreen = panoramicWidth / 2;
    const x360View =  positionView - centerViewOnScreen;
    _360ViewRef.current.style.left = `-${x360View}px`;

  }

  const toggleActiveStateOfLandmark = (index) => {
    setLandmarks (
      landmarks.map((item,i) => {
          if(index === i){
            item.active = !item.active;
          }
          return item;
      })
    );
    if(landmarkActiveIndex){
      setLandmarkActiveIndex(null);
    }else{
      setLandmarkActiveIndex(index);
    }
  }

  return (
    <div className={`panoramic__view ${(arePermissionsAllowed && !showCalibrationPage) ? "active" : ""} ${landmarkActiveIndex ? "pinned" : ""}`} ref={_360ViewRef}>
      { landmarks.map( (item,index) => (
        <Landmark  
          index={index} 
          key={"landmark"+index} 
          landmark={item} 
          panoramicWidth={panoramicWidth}
          orientation={orientation}
          currentFrame={currentFrame}
          landmarkActiveIndex={landmarkActiveIndex}
          toggleActiveStateOfLandmark={toggleActiveStateOfLandmark}
        />
        ))
      }
    </div>
  )
}

export default PanoramicView;
