import React from 'react';
import SanFrancisco from "./SanFrancisco";
import PageNotFound from "../PageNotFound";
import { useParams } from "react-router-dom";
import data from "../../data.js";

const Brochure = () => {
  const { building } = useParams();
  const config = {
    slug: "",
    name: "",
  }
  const [ brochureConfig, setBrochureConfig ] = React.useState({});


  return (
    building === "sanfrancisco" ?
      <SanFrancisco building={building}/>
    : building === "tokyo" ?
      <SanFrancisco building={building}/>
    : building === "lima" ?
      <SanFrancisco building={building}/>
    : building === "trujillo" ?
      <SanFrancisco building={building}/>
    : <PageNotFound/>
  );
}

export default Brochure;
