import React from 'react';
import '../../styles/instructions.css';

const Instructions = (props) => {
    const { startARMap, areInstructionsRead, data } = props;

    return (
        !areInstructionsRead ?
        <div className="instructions">
            <div className="content">
                <h4>{data.title}</h4>
                {data.body.map( (p,index) => (
                  <p key={`instructions-${index}`}>{p}</p>
                ))}
                <button onClick={startARMap}>Start</button>
            </div>
        </div>
        : null
    )
}

export default Instructions;
