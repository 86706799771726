import React from 'react';
import calibrationImage from "../../assets/images/trailmap/calibration-image.png"
import ButtonLoader from '../../components/ButtonLoader';
import { usePageVisibility } from '../../util/Visibility';

const Camera = (props) => {
  const { 
    width, 
    setUpBackgroundWidth,
    cameraStream,
    arePermissionsAllowed,
    showCalibrationPage,
    calibrateManually,
    INTENDED_SENSOR_RESETTING_TIME_MS,
  } = props;
  const cameraRef = React.useRef(null);
  const cameraContainerRef = React.useRef(null);
  usePageVisibility(onPageHide, onPageResume);

  React.useEffect( () => {
    if(cameraStream){
      cameraRef.current.srcObject = cameraStream
      cameraRef.current.play();
    }
  },[cameraStream])

  function onPageHide(){
    cameraRef.current.pause();
  }

  function onPageResume(){
    cameraRef.current.play();
  }

  React.useEffect(() => {
    let panoramicElement = cameraContainerRef.current;
    if (panoramicElement) {
      setUpBackgroundWidth(panoramicElement.clientWidth);
    }
  }, [width]);

  return (
    <div className={`video-container ${arePermissionsAllowed ? "active" : ""}`} ref={cameraContainerRef}>
      <video playsInline={true} ref={cameraRef}>Stream not available ...</video>
      { showCalibrationPage ? 
        <>
          <img alt="" className="calibration-image" src={calibrationImage}/>
          <div className="shadow-section">
            <div className="text">
              <p>Stand in front of the National Park Sign and match the graphic to calibrate your phone</p>
            </div>
          </div>
          <ButtonLoader onClick={calibrateManually} text="Calibrate" animationTimeMs={INTENDED_SENSOR_RESETTING_TIME_MS}/>
        </>
        : null
      }
    </div>
  )
}

export default Camera;
