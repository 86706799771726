import { useState } from 'react';
import { PERMISSION_INITIAL_STATE, PERMISSION_STATUS, GEOLOCATION_INITIAL_STATE } from "./constants.js";
import { getMobileOperatingSystem, getMobileBrowser } from './functions.js';
import data from "../data";

export const useGeolocation = () => {
  const [status, setStatus] = useState(PERMISSION_INITIAL_STATE);
  const [ geolocation, setGeolocation] = useState(GEOLOCATION_INITIAL_STATE);
  const mobileOS = getMobileOperatingSystem();
  const browser = getMobileBrowser();
  const geolocationMessages = data.artrailmap.permissions.geolocation.errorMessages;

  const startGeolocation = async (highAccuracy = false) => {

    const getCoordinates = () => {

      const success = (position) => {
        const { longitude, latitude } = position.coords;
        setGeolocation({latitude, longitude });
        setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
      }
      const handleErrorMessage = (error) => { 
        // let message = geolocationMessages.generic.join(" ");
        let message = {};
        switch ( error.code ) { 
          case error.PERMISSION_DENIED: 
            // message += "User denied the request for Geolocation.";
            // message += geolocationMessages.disabled.join(" ");
            message.generic = geolocationMessages.generic;
            message.troubleshooting = geolocationMessages.troubleshooting[mobileOS] && geolocationMessages.troubleshooting[mobileOS][browser];
            break; 
          case error.POSITION_UNAVAILABLE:
            // message += "Location information is unavailable.";
            message.generic = geolocationMessages.generic; 
            break; 
          case error.TIMEOUT: 
            // message += "The request to get user location timed out."; 
            message.generic = geolocationMessages.generic;
            break; 
          case error.UNKNOWN_ERROR: 
            // message += "An unknown error occurred."; 
            message.generic = geolocationMessages.generic;
            break; 
          default:
            message.generic = geolocationMessages.generic;
            // message += "Location error occurred."
        }
        setStatus({
          status: PERMISSION_STATUS.ERROR, 
          message: message
        });
      }

      setStatus({status: PERMISSION_STATUS.LOADING, message: ""});
      setGeolocation({latitude: "", longitude: "" });
      navigator.geolocation.watchPosition(success, handleErrorMessage, 
        // { maximumAge: 100, timeout: 6000, enableHighAccuracy: highAccuracy});
        { enableHighAccuracy: highAccuracy});
    }
    getCoordinates();
    // const { longitude, latitude } = position.coords;
    // setGeolocation({latitude, longitude });
    // setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
  }

  const calcOrientationDegreesFromCurrentLocationToLandmark = ( currentLocation, landmark) => {
    const phiK = (landmark.latitude * Math.PI) / 180.0;
    const lambdaK = (landmark.longitude * Math.PI) / 180.0;
    const phi = (currentLocation.latitude * Math.PI) / 180.0;
    const lambda = (currentLocation.longitude * Math.PI) / 180.0;
    let psi =
      (180.0 / Math.PI) *
      Math.atan2(
        Math.sin(lambdaK - lambda),
        Math.cos(phi) * Math.tan(phiK) -
          Math.sin(phi) * Math.cos(lambdaK - lambda)
      );
    if (psi < 0) {
      psi = psi + 360;
    }
    return Math.round(psi);
  }

  return {
    geolocation,
    status,
    startGeolocation,
    calcOrientationDegreesFromCurrentLocationToLandmark,
  };
}