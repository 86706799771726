import React from "react";
import '../styles/header.css';
import { Link } from 'react-router-dom'
import SalesforceLogo from "../sf-logo.svg";

const Header = (props) => {
  return (
    <header>
        <div className="container">
          <img src={SalesforceLogo} alt=""/>
          <Link to={`/${props.building}/`}>
            <p>Brochure</p>
          </Link>
          <Link to={`/${props.building}/trailmap`}>
            <p>AR Trail Map</p>
          </Link>
        </div>
    </header>
  )
}

export default Header;