import { useState, useEffect } from 'react';
import { getMobileOperatingSystem, getMobileBrowser } from './functions.js';
import { PERMISSION_INITIAL_STATE, PERMISSION_STATUS } from "./constants.js";
import data from "../data";

export const useCamera = () => {
  const [status, setStatus] = useState(PERMISSION_INITIAL_STATE);
  const [ cameraStream, setCameraStream] = useState(null);
  const mobileOS = getMobileOperatingSystem();
  const browser = getMobileBrowser();
  const cameraMessages = data.artrailmap.permissions.camera.errorMessages;

  const startCamera = async () => {
    try {
      setStatus({status: PERMISSION_STATUS.LOADING, message: ""});
      let front = false;
      const constrains = {video: { facingMode: (front ? "user" : "environment") } };
      const stream = await navigator.mediaDevices.getUserMedia(constrains);
      setCameraStream(stream);
      setStatus({status: PERMISSION_STATUS.CORRECT, message: ""});
    } catch(error) {
      // let message = cameraMessages.generic.join(" ");
      let message = {};
      if (error.name == "NotReadableError" || error.name == "TrackStartError") {
        //webcam or mic are already in use 
        // message += error.message
        message.generic = cameraMessages.generic;
      } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
        //permission denied in browser 
        // message += error.message 
        // message += cameraMessages.disabled.join(" ");
        message.generic = cameraMessages.generic;
        message.troubleshooting = cameraMessages.troubleshooting[mobileOS] && cameraMessages.troubleshooting[mobileOS][browser];
      } else {
        message.generic = cameraMessages.generic;
      }
      setStatus({status: PERMISSION_STATUS.ERROR, message});
    }
  }

  useEffect(() => {
    return () => {
      if(cameraStream){
        cameraStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    }
  }, [cameraStream]);

  return {
    cameraStream,
    status,
    startCamera,
  };
}
