import React from 'react';
import { ReactComponent as ArrowShadow } from '../../assets/icons/arrow-shadow.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';

const Landmark = (props) => {
  const { index, landmark, panoramicWidth, orientation, currentFrame,
    toggleActiveStateOfLandmark, landmarkActiveIndex } = props;
  const landmarkRef = React.useRef(null);
  const informationRef = React.useRef(null);
  const animationTimeout = React.useRef(null);
  const MAX_DEGREES_DIFFERENCE_TO_SHOW_ARROWS = 30;
  const ICON_IMAGE_HEIGHT_PX = 120;
  const ICON_IMAGE_SELECTED_HEIGHT_PX = 180;
  const ICON_HEIGHT_DIFFERENCE_PX = (ICON_IMAGE_SELECTED_HEIGHT_PX - ICON_IMAGE_HEIGHT_PX) / 2;
  const INFORMATION_BOX_HEIGHT_ON_SELECTED_PX = 256;
  const INFORMATION_BOX_OVERLAPPED_ON_ICON_PX = 25;
  const LANDMARK_MARGIN_BOTTOM_VH = 3;
  const FIXED_ANIMATION_TIME_MS = 1000;
  const FIXED_ANIMATION_TIME_TRESHOLD_MS = 100;
  let arrowClass = "inactive";
  if(landmark.active){
    arrowClass = checkIfArrowIndicatorsAreNeeded();
  }

  React.useEffect( () => {
    landmarkRef.current.style.top=`calc(100% 
      - ${ICON_IMAGE_SELECTED_HEIGHT_PX}px 
      - ${INFORMATION_BOX_HEIGHT_ON_SELECTED_PX}px 
      - ${LANDMARK_MARGIN_BOTTOM_VH}vh 
      + ${INFORMATION_BOX_OVERLAPPED_ON_ICON_PX}px
      + ${ICON_HEIGHT_DIFFERENCE_PX}px)`;
  },[])

  React.useEffect( () => {
    placeLandmarkInBackground()
  },[panoramicWidth,landmark.degrees])

  React.useEffect( () => {
    if(landmarkActiveIndex && landmarkActiveIndex > +landmarkRef.current?.style.zIndex){
      landmarkRef.current.style.zIndex = +landmarkRef.current?.style.zIndex + 1;
    }
  },[landmarkActiveIndex])

  function checkIfArrowIndicatorsAreNeeded(){
    const degreesOff = degreesAwayOfLandmark()
    if( degreesOff > MAX_DEGREES_DIFFERENCE_TO_SHOW_ARROWS){
      return "left";
    }else if( degreesOff < - MAX_DEGREES_DIFFERENCE_TO_SHOW_ARROWS){
      return "right";
    }else{
      return "inactive";
    }
  }

  function degreesAwayOfLandmark(){
    return ((currentFrame - 1) * 360 + orientation) - 
      (landmark.iteration * 360 + landmark.degrees);
  }

  const placeLandmarkInBackground = () => {
    const positionBackgroundFrame = landmark.iteration * (panoramicWidth * 12);
    const positionLandmarkDegree= (panoramicWidth * 12) * landmark.degrees / 360;
    const left =  positionBackgroundFrame + positionLandmarkDegree;
    landmarkRef.current.style.left = `${left}px`
  }

  const animateInLandmark = () => {
    let viewportLeftValue = orientation - landmark.degrees
    viewportLeftValue = Math.abs(viewportLeftValue - 15 );
    viewportLeftValue = viewportLeftValue / 30 * 100;
    landmarkRef.current.style.left = `${viewportLeftValue}%`;
    landmarkRef.current.style.width = `80vw`;
    landmarkRef.current.style.zIndex = `100000`;
    informationRef.current.style.width = `auto`;
    
    setTimeout( () => {
      landmarkRef.current.style.transition=`${FIXED_ANIMATION_TIME_MS - FIXED_ANIMATION_TIME_TRESHOLD_MS}ms`
      landmarkRef.current.style.left=`${panoramicWidth/2}px`
      landmarkRef.current.style.top=`calc(100% 
        - ${ICON_IMAGE_SELECTED_HEIGHT_PX}px 
        - ${INFORMATION_BOX_HEIGHT_ON_SELECTED_PX}px
        - ${LANDMARK_MARGIN_BOTTOM_VH}vh 
        + ${INFORMATION_BOX_OVERLAPPED_ON_ICON_PX}px)`;
    },FIXED_ANIMATION_TIME_TRESHOLD_MS)
  }

  const animateOutLandmark = () => {
    let landmarkPosition = moveLandmark(orientation);
    landmarkRef.current.style.transition="none";
    landmarkRef.current.style.left = `${landmarkPosition}px`;
    landmarkRef.current.style.top=`calc(100% 
      - ${ICON_IMAGE_SELECTED_HEIGHT_PX}px 
      - ${INFORMATION_BOX_HEIGHT_ON_SELECTED_PX}px
      - ${LANDMARK_MARGIN_BOTTOM_VH}vh 
      + ${INFORMATION_BOX_OVERLAPPED_ON_ICON_PX}px)`;
    
    setTimeout( () => {
      landmarkRef.current.style.transition=`${FIXED_ANIMATION_TIME_MS - FIXED_ANIMATION_TIME_TRESHOLD_MS}ms`;
      landmarkRef.current.style.top=`calc(100% 
        - ${ICON_IMAGE_SELECTED_HEIGHT_PX}px 
        - ${INFORMATION_BOX_HEIGHT_ON_SELECTED_PX}px
        - ${LANDMARK_MARGIN_BOTTOM_VH}vh 
        + ${INFORMATION_BOX_OVERLAPPED_ON_ICON_PX}px
        + ${ICON_HEIGHT_DIFFERENCE_PX}px)`;
      placeLandmarkInBackground();
    },FIXED_ANIMATION_TIME_TRESHOLD_MS)
    setTimeout(function () {
      informationRef.current.style.width = `0px`;
      landmarkRef.current.style.width = `auto`;
      landmarkRef.current.style.transition = `none`;
      landmarkRef.current.style.zIndex = `0`;
      // setIsAnimatingOut(false);
    }, FIXED_ANIMATION_TIME_MS)
    // setArrowClass("inactive");
  }
  
  const moveLandmark = (compass) => {
    const positionBackgroundFrame = (currentFrame - 1) * (panoramicWidth * 12);
    const positionBackgroundDegrees= (panoramicWidth * 12) * compass / 360
    const left =  positionBackgroundFrame + positionBackgroundDegrees;
    return left;
  }

  const toogleLandmark = () => {
    if(landmark.active){
      customDebounce( () => {
        toggleActiveStateOfLandmark(index);
        animateOutLandmark();
      });
    }else{
      customDebounce( () => {
        toggleActiveStateOfLandmark(index);
        animateInLandmark();
      });
    }
  }

  const customDebounce = (func) => {
    if(animationTimeout.current === null){
      func();
      animationTimeout.current = setTimeout( () => {
        animationTimeout.current = null
      },FIXED_ANIMATION_TIME_MS);
    }
  }

  return (
    <div 
      ref={landmarkRef} 
      className={`landmark informational-popup ${landmark.active ? "active" : "inactive"}`}
      style={{zIndex: index}}
    >
      <div className="image" 
        style={{height: landmark.active ? `${ICON_IMAGE_SELECTED_HEIGHT_PX}px` : `${ICON_IMAGE_HEIGHT_PX}px`}}>
        <div className="image-container">
          <img 
            alt="" 
            src={require(`./../../assets/images/trailmap/${landmark.src}`)}
            onClick={toogleLandmark}
          />
          <ArrowShadow className={`arrow ${arrowClass}`}/>
        </div>
        <button onClick={toogleLandmark}>
          <CloseIcon/>
        </button>
      </div>
      <div className="information"ref={informationRef}>
        <div className="overflowed">
            <h4>{landmark.name}</h4>
            <p>{landmark.description}
            </p>
        </div>
      </div>
    </div>
  )
}

export default Landmark;
