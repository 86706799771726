export const PERMISSION_STATUS = {
  INITIAL: "initial",
  LOADING: "loading",
  CORRECT: "correct",
  ERROR: "error",
}
  
export const PERMISSION_INITIAL_STATE = {
  status: PERMISSION_STATUS.INITIAL,
  message: "",
}

export const GEOLOCATION_INITIAL_STATE = {
  latitude: null,
  longitude: null,
}