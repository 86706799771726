import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import './styles/app.css';
import Brochure from './pages/brochure/index';
import PageNotFound from './pages/PageNotFound';
import ARTrailMap from './pages/ARTrailMap';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route exact path="/:building" element={<Brochure />}>
          </Route>
          <Route exact path="/:building/trailmap" element={<ARTrailMap />}>
          </Route>
          <Route path="*" element={<PageNotFound />}>
          </Route>
        </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
