import React from 'react';
import { useViewport } from '../../util/hooks';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';

const ModalCard = (props) => {
  const { height } = useViewport();
  const { cleanCardData } = props;
  const [ isActive, setIsActive] = React.useState(false);
  const MODAL_CARD_TRANSITION_TIMING = 1000;
  const modalRef = React.useRef(null);

  React.useEffect( () => {
    if(isActive){
      document.body.style.overflow = "hidden";
    }else{
      document.body.style = "";
    }
  },[isActive])

  React.useEffect( () => {
    modalRef.current.style.minHeight = `${height}px`
    modalRef.current.style.height = `${height}px`
  },[height])

  React.useEffect( () => {
    if(props.card.title){
      setIsActive(true);
    }else{
      setIsActive(false); 
    }
  },[props.card])

  const closeCard = () => {
    setIsActive(false);
    setTimeout( () => {
      cleanCardData();
    },MODAL_CARD_TRANSITION_TIMING);
  }

  return (
    <div className={`modal-card${isActive ? " active" : ""}`} style={{transition: `top ${MODAL_CARD_TRANSITION_TIMING}ms`}} ref={modalRef}>
      <div className="header">
        <div className="arrow__container" onClick={closeCard}>
            <Arrow />
        </div>
      </div>
      <h4>{props.card.title}</h4>
      <div className="article">
        <div className="body">
          {props.card.backInfo.map( (item,index) => (
            <p key={`${props.card.title}-paragraph-${index}`}>{item}</p>
          ))}
        </div>
        <div className={`image__container ${props.card.slug}`}>
          {props.card.modalImage ?
            <img 
              src={require(`./../../assets/images/brochure/sanfrancisco/${props.card.modalImage}`)} 
              className="image" 
              alt=""
            />
            : null
          }
        </div>
      </div>
    </div>
  );
}

export default ModalCard;
