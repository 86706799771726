import React from 'react';
import '../../styles/arTrailMap.css';
import { useViewport } from '../../util/hooks.js';
import { useParams } from "react-router-dom";
import { Instructions, Permissions, CompassIcon, PanoramicView, Monitoring, Video } from "./Components";
import Header from '../../components/Header';
import data from '../../data';

import { useDeviceOrientation } from '../../util/Orientation';
import { useGeolocation } from '../../util/Geolocation';
import { useCamera } from "../../util/Camera"
import { PERMISSION_STATUS } from '../../util/constants';
import { usePageVisibility } from '../../util/Visibility';

const ARTrailMap = () => {
  const { building } = useParams();
  const copy = data.copy.find( item => item.id === building);
  const trailMapCopy = data.artrailmap;
  const {
    orientation,
    status: statusOrientation,
    startOrientation,
    startCalibration,
    stopAllSensors,
    type,
    INTENDED_SENSOR_RESETTING_TIME_MS,
  } = useDeviceOrientation();
  const [ tooltip, setTooltip] = React.useState(false);
  const [ tooltip2, setTooltip2] = React.useState(false);
  usePageVisibility(() => {}, onPageResume);

  function onPageResume(){
    if(type === "relative"){  
      setTimeout( () => setTooltip2(true) , 5000);
      setTimeout( () => setTooltip2(false) , 10000);
    }
    stopAllSensors();
    startOrientation();
    // setTimeout( () => alert("Remember to calibrate again when you resume the page"), 5000);
  }

  const { 
    geolocation, 
    startGeolocation, 
    calcOrientationDegreesFromCurrentLocationToLandmark, 
    status: statusGeolocation 
  } = useGeolocation();

  const { 
    cameraStream, 
    startCamera, 
    status: statusCamera 
  } = useCamera();

  const { width, height } = useViewport();
  const mainRef = React.useRef(null);

  const [ panoramicWidth, setPanoramicWidth] = React.useState(0);
  const [ app, setApp] = React.useState({
    areInstructionsRead: false,
    arePermissionsAllowed: false,
    showCalibrationPage: false,
  });

  React.useEffect( () => {
    mainRef.current.style.minHeight = `${height - 50}px`
    mainRef.current.style.height = `${height - 50}px`
  },[height])

  React.useEffect( () => {
    if(statusOrientation.status === PERMISSION_STATUS.CORRECT &&
      statusCamera.status === PERMISSION_STATUS.CORRECT &&
      statusGeolocation.status === PERMISSION_STATUS.CORRECT){
      setApp( (app) => {
        return {...app, arePermissionsAllowed: true}
      });
    }
  },[statusOrientation.status, statusCamera.status,statusGeolocation.status])

  const setUpBackgroundWidth = (clientWidth) => {
    setPanoramicWidth(clientWidth);
  }

  React.useEffect( () => {
    if(app.arePermissionsAllowed){
      setTimeout( () => setTooltip(true) , 5000);
      setTimeout( () => setTooltip(false) , 10000);
    }
  },[app.arePermissionsAllowed])

  const startARMap = () => {
    setApp({
      ...app,
      areInstructionsRead: true,
    });
  }

  const calibrateManually = () => {
    setApp({
      ...app,
      showCalibrationPage: false,
    });
    startCalibration();
  }

  return (
    <div className="armap">
      <Header building={building}/>
      <div className="main" ref={mainRef}>
        <Instructions 
          areInstructionsRead={app.areInstructionsRead}
          startARMap={startARMap}
          data={trailMapCopy.instructions}
        />
        <div className="panoramic">
          {/* For monitoring values */}
          {process.env.REACT_APP_MONITORING_APP && 
            <Monitoring 
              orientation={orientation} 
              startGeolocation={startGeolocation}
              geolocation={geolocation}
            />
          }
          <div className={`calibration-icon-test ${(app.arePermissionsAllowed && !app.showCalibrationPage) ? "active" : ""}`} onClick={() => setApp({...app,showCalibrationPage: true})}>
            <span className={`tooltip ${tooltip ? "active" : ""}`}>If your landmarks are off, you can calibrate here</span>
            <span className={`tooltip ${tooltip2 ? "active" : ""}`}>Remember to calibrate again when you resume the page</span>
            <img src="/calibrate-icon.png"/>
          </div>
          {/* <button style={{position: "absolute",zIndex: 52123, bottom: "4%", left: "50%"}} onClick={revokeAccessAsync}>Stop sensors</button> */}
          <Permissions
            arePermissionsAllowed={app.arePermissionsAllowed}
            startCamera={startCamera}
            startOrientation={startOrientation}
            startGeolocation={startGeolocation}
            statusCamera={statusCamera}
            statusGeolocation={statusGeolocation}
            statusOrientation={statusOrientation}
            data={trailMapCopy.permissions}
          />
          <CompassIcon 
            arePermissionsAllowed={app.arePermissionsAllowed}
            showCalibrationPage={app.showCalibrationPage}
            orientation={orientation}
          />
          <PanoramicView 
            showCalibrationPage={app.showCalibrationPage}
            arePermissionsAllowed={app.arePermissionsAllowed}
            data={copy}
            orientation={orientation}
            panoramicWidth={panoramicWidth}
            geolocation={geolocation}
            calcOrientationDegreesFromCurrentLocationToLandmark={calcOrientationDegreesFromCurrentLocationToLandmark}
          />
          <Video 
            arePermissionsAllowed={app.arePermissionsAllowed}
            showCalibrationPage={app.showCalibrationPage}
            cameraStream={cameraStream} 
            setUpBackgroundWidth={setUpBackgroundWidth} 
            width={width}
            calibrateManually={calibrateManually}
            INTENDED_SENSOR_RESETTING_TIME_MS={INTENDED_SENSOR_RESETTING_TIME_MS}
          />
        </div>
      </div>
    </div>
  );
}

export default ARTrailMap;
