const data = {
    building: [
      "sanfrancisco",
      "tokyo",
    ],
    cards: [
        {
          slug: "card-salesforce",
          title: "Who is Salesforce?",
          color: "",
          image: "card-image-salesforce.png",
          backInfo: [
            "Salesforce is the global leader in customer relationship management (CRM), bringing companies and customers together. Founded in 1999, Salesforce enables companies of every size and industry to take advantage of powerful technologies — cloud, mobile, social, blockchain, voice, and artificial intelligence — to connect to their customers in a whole new way. For more information, visit salesforce.com.",
            "Salesforce pioneered the Pledge 1% model where we donate 1% time, 1% product, 1% profit to improve the world. To date, globally we have provided 52,000+ nonprofits, educational institutions, and philanthropic organizations with our technology, donated $500 million in grants, and contributed more than 6.2 million hours of employee volunteering. Today, more than 12,000 companies have joined the giving movement and taken the pledge."
          ],
          modalImage: "card-modal-image-salesforce.gif",
        },
        {
          slug: "card-ohana-floors",
          title: "‘Ohana Floors",
          color: "",
          image: "card-image-ohana.png",
          backInfo: [
            "‘Ohana is the Hawaiian word for family and describes the bond between our employees, customers, partners, and the communities in which we live and work.",
            "Our ‘Ohana Floor is a designated open-hospitality space found at the top of the Tower, outfitted with an exhibition kitchen, barista bar, and 360-degree views for our community to enjoy.",
            "Equality is one of our core values, and it’s at the heart of everything we do. The space is available to nonprofits, education groups, and foundations that align with our values, for their own events on weeknights and weekends at no cost.",
            "Today, our one-of-a-kind ‘Ohana Floors are open in Atlanta, Tokyo, New York, San Francisco, London, and Indianapolis. We look forward to adding more soon in Dublin, Chicago, and Sydney.",
          ],
          modalImage: "card-modal-image-ohana.png",
        },
        {
          slug: "card-sustainability",
          title: "Sustainability",
          color: "",
          image: "card-image-sustainability.png",
          backInfo: [
            "On track to achieve LEED v4 Platinum (Commercial Interiors) Certification, Fitwel Certification, Zero Carbon Certification, and Fitwel Viral Response Certification.",
            "At least 75% of the construction waste from the build-out of Salesforce’s floors will be diverted from landfill.",
            "The project is powered by 100% renewable energy, and all carbon emitted (from energy and materials) is covered by our net zero program.",
            "The building uses 50% less water than a typical building of its size.",
          ],
          modalImage: "card-modal-image-sustainability.png",
        }
    ],
    artrailmap: {
      instructions: {
        title: "Welcome to Salesforce Tower San Francisco",
        body: [
          "Wow, San Francisco sure is beautiful, isn’t it? And there’s even more to it than what meets the eye. The surrounding views are filled with stories. We invite you to get to know a few while enjoying this augmented reality tour.",
          "Simply answer a few quick questions to get set up. Then, as you admire the sweeping vistas, point your phone at local landmarks and we’ll tell you some fun facts and bits of San Francisco lore.",
        ]
      },
      permissions: {
        orientation: {
          description: "Let us access your orientation so your experience goes smoothly.",
          errorMessages: {
            generic: [
              "Oops, that didn’t work.",
              "We need access to your device’s orientation settings so the AR Trail Map looks its best.",
            ],
            troubleshooting: {
              iOS:{
                safari: [
                  "Close the Safari tab",
                  "Open your camera and scan the brochure QR code",
                  "Return to the trailmap",
                  'Click the Orientation "Enable" button',
                  'Click "Allow" for motion and orientation permission',
                ],
                chrome: [
                  "Close the Chrome tab",
                  "Open your camera and scan the brochure QR code",
                  "Return to the trailmap",
                  'Click the Orientation "Enable" button',
                  'Click "Allow" for motion and orientation permission',
                ],
              },
            },
          },
        },
        geolocation: {
          description: "We’ll use your location to make sure everything lines up.",
          errorMessages: {
            generic: [
              "Hmm, something’s not right.",
              "We need access to your device’s geolocation so we know where the landmarks are in relation to you.",
            ],
            troubleshooting: {
              iOS: {
                safari: [
                  "Go to settings app on your device",
                  'In the search bar, search for "Safari"',
                  'Scroll down to the "Location" option',
                  'Check if "Ask" or "Allow while using the app" or "Allow once" is checked. If not, select either of them',
                  'Go back to your browser tab and Refresh the tab',
                  'In the Trailmap, Click the geolocation "Enable" button',
                  'Click "Allow" for Location',
                ],
                chrome: [
                  "Go to settings app on your device",
                  'In the search bar, search for "Chrome"',
                  'Scroll down to the "Location" option',
                  'Check if "Ask" or "Allow" is checked. If not, select either of them',
                  'Go back to your browser tab and Refresh the tab',
                  'In the Trailmap, Click the geolocation "Enable" button',
                  'Click "Allow" for Location',
                ],
              }
            },
          },
        },
        camera: {
          description: "One last step. We need to use your camera to spot the landmarks.",
          errorMessages: {
            generic: [
              "Uh oh, your camera is off.",
              "Without access we can’t help you spot the landmarks.",
            ],
            troubleshooting: {
              iOS:{
                safari: [
                  "Go to settings app on your device",
                  'In the search bar, search for "Safari"',
                  'Scroll down to the "Camera" option',
                  'Check if "Ask" or "Allow" is checked. If not, select either of them',
                  'Go back to your browser tab and Refresh the tab',
                  'In the Trailmap, Click the camera "Enable" button',
                  'Click "OK" to allow the camera',
                ],
                chrome: [
                  "Go to settings app on your device",
                  'In the search bar, search for "Chrome"',
                  'Scroll down to the "Camera" option',
                  'Check if toggle status is on. If not, enable it',
                  'Go back to your browser tab and Refresh the tab',
                  'In the Trailmap, Click the camera "Enable" button',
                  'Click "OK" to allow the camera',
                ],
              },
            },
          },
        },
      },
    },
    copy: [
      {
        id: "trujillo",
        landmarks: [
          {
            src: "sanfrancisco/golden-gate-bridge.png",
            name: "Estadio Mansiche",
            coordinates: {
              latitude: -8.106272117258563,
              longitude: -79.03071801863089,
            },
            description: "Estadio Mansiche"
          },
          {
            src: "sanfrancisco/coit-tower.png",
            name: "Hospital Primavera",
            coordinates: {
              latitude: -8.10155013561094,
              longitude: -79.03575424234242,
            },
            description: "Hospital Primavera"
          },
          {
            src: "sanfrancisco/transamerica-building.png",
            name: "Mall Aventura",
            coordinates: {
              latitude: -8.102525279432252,
              longitude: -79.04775759465514,
            },
            description: "Mall AVentura"
          },
          {
            src: "sanfrancisco/palace-fine-arts.png",
            name: "Open Plaza",
            coordinates: {
              latitude: -8.09500903620203,
              longitude: -79.02334776888851,
            },
            description: "Open Plaza"
          },
          {
            src: "sanfrancisco/ferry-building.png",
            name: "Ovalo Mochica",
            coordinates: {
              latitude: -8.094867091674987,
              longitude: -79.03910091665767,
            },
            description: "Ovalo Mochica"
          },
        ], 
      },
      {
        id: "lima",
        landmarks: [
          {
            src: "sanfrancisco/golden-gate-bridge.png",
            name: "Touluse",
            coordinates: {
              latitude: -12.093940633897683,
              longitude: -77.05820131690714,
            },
            description: "Edificio toulouse"
          },
          {
            src: "sanfrancisco/coit-tower.png",
            name: "Lima Cricket Club",
            coordinates: {
              latitude: -12.098139158874528,
              longitude: -77.06138177789883,
            },
            description: "Lima Cricket Club"
          },
          {
            src: "sanfrancisco/transamerica-building.png",
            name: "Real Plaza Salaverry",
            coordinates: {
              latitude: -12.089995411358062,
              longitude: -77.05270359990607,
            },
            description: "plaza salaverry"
          },
          {
            src: "sanfrancisco/palace-fine-arts.png",
            name: "Primer trebol",
            coordinates: {
              latitude: -12.090879030222688,
              longitude: -77.02282020434195,
            },
            description: "Primer trebol antes del jockey"
          },
        ]
      },
      {
        id: "sanfrancisco",
        landmarks: [
          {
            src: "sanfrancisco/golden-gate-bridge.png",
            name: "Golden Gate Bridge",
            coordinates: {
              latitude: 37.819929,
              longitude: -122.478255,
            },
            degrees: 30,
            description: "The iconic “International Orange” was only meant to be a primer, but looked so great the architect kept it. The original lead-based paint took 30 years, from 1965 to 1995, to remove and replace with a safer zinc-based paint."
          },
          {
            src: "sanfrancisco/palace-fine-arts.png",
            name: "Palace of Fine Arts",
            coordinates: {
              latitude: 37.801991,
              longitude: -122.448656,
            },
            degrees: 60,
            description: "The weeping nymphs atop the columns were meant to be shrouded in flowers and vines to evoke the melancholy feel of a Roman ruin. But the project went over budget, the plants were cut, and the women remained exposed. Sorry, ladies."
          },
          {
            src: "sanfrancisco/transamerica-building.png",
            name: "Transamerica Building",
            coordinates: {
              latitude: 37.795162,
              longitude: -122.402728,
            },
            degrees: 90,
            description: "The Transamerica Pyramid was the tallest building in San Francisco from the time it was built in 1972 until 2017 when the Salesforce Tower was built. It had a good run."
          },
          {
            src: "sanfrancisco/coit-tower.png",
            name: "Coit Tower",
            coordinates: {
              latitude: 37.802395,
              longitude: -122.405822,
            },
            degrees: 92,
            description: "The tower is named for its benefactress, Lillie HItchcock Coit. A true San Francisco character, “Miss Lil” was known for her love of firefighting, gambling and sharpshooting, and her unconventional habit of wearing trousers."
          },
          {
            src: "sanfrancisco/alcatraz.png",
            name: "Alcatraz",
            coordinates: {
              latitude: 37.826977,
              longitude: -122.422956,
            },
            degrees: 94,
            description: "Five prisoners staged an elaborate breakout in 1962 — digging through the walls with sharpened spoons and leaving dummy heads in their beds. Their belongings were found in the bay, but no bodies. Did they get away?"
          },
          {
            src: "sanfrancisco/angel-island.png",
            name: "Angel Island",
            coordinates: {
              latitude: 37.869333,
              longitude: -122.426397,
            },
            degrees: 120,
            description: "Today the island offers hike-in campsites and over nine miles of bike trails. Take the ferry there and you can even rent a bike on site. Just be sure to be prepared for that famous bay breeze. It can get blustery."
          },
          {
            src: "sanfrancisco/ferry-building.png",
            name: "Ferry Building",
            coordinates: {
              latitude: 37.7955703,
              longitude: -122.3933208,
            },
            degrees: 150,
            description: "Completed in 1898, this 660-foot building was the city’s largest construction project in the 19th century. On top is a 245-foot clock tower whose chimes can be heard every hour and a half. Listen carefully. You might be able to hear them from here!"
          },
          {
            src: "sanfrancisco/bay-bridge.png",
            name: "Bay Bridge",
            coordinates: {
              latitude: 37.798280,
              longitude: -122.377818,
            },
            degrees: 200,
            description: "The Bay Bridge is part of Interstate 80. If you were to leave Salesforce Tower and take the Bay Bridge towards Oakland, you could drive all the way to New Jersey. Road trip anyone?"
          },
          {
            src: "sanfrancisco/treasure-island.png",
            name: "Treasure Island",
            coordinates: {
              latitude: 37.823552,
              longitude: -122.370648,
            },
            degrees: 230,
            description: "Do you see the lighthouse? Built in 1874, it was used to safely guide ferries in the bay. But this isn’t just some relic. The lighthouse is still in use today with its original lens! Talk about an enlightening fact!"
          },
          {
            src: "sanfrancisco/giants-stadium.png",
            name: "San Francisco Giants Stadium",
            coordinates: {
              latitude: 37.778572,
              longitude: -122.389717,
            },
            degrees: 260,
            description: "Hitting a home run at this baseball stadium may come with a splash. Over 150 “splash hits” have landed in McCovey Cove since Barry Bonds did it first on May 1st, 2000!"
          },
          {
            src: "sanfrancisco/chase-center.png",
            name: "Chase Center",
            coordinates: {
              latitude: 37.768009,
              longitude: -122.387787,
            },
            degrees: 290,
            description: "The Chase Center is home to the Golden State Warriors and the men’s and women’s basketball teams from the University of San Francisco. How many points would it be if you made a shot from here?"
          },
          {
            src: "sanfrancisco/sutro-tower.png",
            name: "Sutro Tower",
            coordinates: {
              latitude: 37.755221,
              longitude: -122.452762,
            },
            degrees: 320,
            description: "The tower was built to expand radio and television reception to neighborhoods that were blocked by the city’s many hills. The first broadcast went live on July 4, 1973 and the rest is history!"
          },

        ],
      },
      {
        id: "tokyo",
        landmarks: [
          {
            src: "tokyo/Asakusa Senso-Ji.png",
            name: "Asakusa Senso-Ji",
            coordinates: {
              latitude: -8.105826,
              longitude: -79.030722,
            },
            mode: "development",
            hardcodedDegrees: 0,
            description: "Located at 0 degrees ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Hibiya Park.png",
            name: "Hibiya Park",
            // from 0 to 360 degrees, 0 is north - 180 is south
            coordinates: {
              latitude: -8.101539,
              longitude: -79.035792,
            },
            mode: "development",
            hardcodedDegrees: 30,
            description: "Located at 30 degrees ipsum dolor sit ariustibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Imperial Palace.png",
            name: "Imperial Palace",
            coordinates: {
              latitude: -8.088948,
              longitude: -79.024963,
            },
            mode: "development",
            hardcodedDegrees: 60,
            description: "Located at 60 degrees ipsum dolor sit ariustibex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Meji Shrine.png",
            name: "Meji Shrine",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 90,
            description: "Located at 90 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Ministry of Justice.png",
            name: "Ministry of Justice",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 120,
            description: "Located at 120 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Mount Fuji.png",
            name: "Mount Fuji",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 150,
            description: "Located at 150 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Shinjuku Gyoen Garden.png",
            name: "Shinjuku Gyoen Garden",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 180,
            description: "Located at 180 degrees Lorem ipndum ac."
          },
          {
            src: "tokyo/Tokyo Metropolitan Building.png",
            name: "Tokyo Metropolitan Building",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 210,
            description: "Located at 210 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Tokyo Sky Tree.png",
            name: "Tokyo Sky Tree",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 240,
            description: "Located at 240 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Tokyo Tower.png",
            name: "Tokyo Tower",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 270,
            description: "Located at 270 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Toranomon Hills Mori Tower.png",
            name: "Toranomon Hills Mori Tower",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 300,
            description: "Located at 300 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
          {
            src: "tokyo/Toyosu Fish Market.png",
            name: "Toyosu Fish Market",
            coordinates: {
              latitude: -8.030738,
              longitude: -79.062121,
            },
            mode: "development",
            hardcodedDegrees: 330,
            description: "Located at 330 degrees Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
          },
        ],
      },
    ],
    landmarks: [
      {
        src: "alcatraz.png",
        name: "Alcatraz",
        coordinate: 50
      },
      {
        src: "ggbridge.png",
        name: "Bridge",
        // from 0 to 360 degrees, 0 is north - 180 is south
        coordinate: 200
      },
      {
        src: "cablecar.png",
        name: "Cablecar",
        coordinate: 270
      },
      {
        src: "lombardstreet.png",
        name: "Lombard Street",
        coordinate: 310
      },
      {
        src: "chinatown.png",
        name: "China Town",
        coordinate: 360
      }
    ],
    landmarks2: [
      {
        src: "alcatraz.png",
        name: "Mansiche",
        coordinates: {
          latitude: -8.105826,
          longitude: -79.030722,
        },
        description: "Mansiche Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
      },
      {
        src: "ggbridge.png",
        name: "Hospotal Primavera",
        // from 0 to 360 degrees, 0 is north - 180 is south
        coordinates: {
          latitude: -8.101539,
          longitude: -79.035792,
        },
        description: "Hospital ipsum dolor sit ariustibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
      },
      {
        src: "cablecar.png",
        name: "Hermelinda",
        coordinates: {
          latitude: -8.088948,
          longitude: -79.024963,
        },
        description: "Hermelinda ipsum dolor sit ariustibex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
      },
      {
        src: "lombardstreet.png",
        name: "El Milagro",
        coordinates: {
          latitude: -8.030738,
          longitude: -79.062121,
        },
        description: "Milagro Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non risus vitae lectus varius molestie in eget est. Pellentesque nec varius felis. Vestibulum rhoncus, nunc et mattis iaculis, tellus est volutpat justo Nunc faucibus metus vel fringilla pulvinar. Pellentesque ullamcorper porttitor dui, nec pharetra nisl pretium a. Integer quis euismod velit. In maximus velit venenatis, egestas sapien vitae, efficitur lorem.Nulla facilisi. Sed at posuere est. Ut vitae euismod ex, accumsan euismod risus. Nulla rhoncus orci nisl, nec pharetra nibh bibendum ac."
      },
    ],
    realLandmarks: [
      

    ],

}
export default data