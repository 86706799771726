import React from 'react';
import '../../styles/compassIcon.css';
import compassArrow from "../../assets/images/trailmap/compass-arrow.png";

const CompassIcon = (props) => {
    const { orientation, arePermissionsAllowed, showCalibrationPage } = props;
    const compassCircleRef = React.useRef(null);
    const compassArrowRef = React.useRef(null);
    const northRef = React.useRef(null);
    const eastRef = React.useRef(null);
    const southRef = React.useRef(null);
    const westRef = React.useRef(null);

    React.useEffect( () => {
        rotateCompassIcon(orientation);
    }, [orientation]);

    const rotateCompassIcon = (compass) => {
      compassCircleRef.current.style.transform = `rotate(-${compass}deg`;
      compassArrowRef.current.style.transform = `rotate(${compass}deg`;
      northRef.current.style.transform = `rotate(${compass}deg`;
      eastRef.current.style.transform = `rotate(${compass}deg`;
      southRef.current.style.transform = `rotate(${compass}deg`;
      westRef.current.style.transform = `rotate(${compass}deg`;
    }

    return (
      <div className={`compass-container ${arePermissionsAllowed && !showCalibrationPage ? "active" : ""}`} ref={compassCircleRef}>
        <img ref={compassArrowRef} alt="" className="compass-arrow" src={compassArrow}/>
        <span ref={northRef} className="coordinate north">N</span>
        <span ref={eastRef} className="coordinate east">E</span>
        <span ref={southRef} className="coordinate south">S</span>
        <span ref={westRef} className="coordinate west">W</span>
        <div className="triangle north"></div>
        <div className="triangle east"></div>
        <div className="triangle south"></div>
        <div className="triangle west"></div>
      </div>
    )
}

export default CompassIcon;
